<template>
  <vca-column>
    <vca-row>
      <vca-card>
        <h3>{{ $t("events.list.type_filter.header") }}</h3>
        <vca-checkbox
          v-for="eventtype in eventtypes"
          v-model="value.type"
          :key="eventtype.value"
          :id="eventtype.value"
          >{{
            $t("events.eventtypes." + eventtype.value + ".title")
          }}</vca-checkbox
        >
      </vca-card>
      <vca-card>
        <h3>{{ $t("events.list.crew.header") }}</h3>
        <vca-dropdown
          v-model="value.crews"
          :multiple="true"
          :options="crewList"
          label=""
          :placeholder="$t('users.list.crew.select.placeholder')"
        ></vca-dropdown>
        <vca-field-row v-if="user">
          <button
            v-if="user.crew.id != ''"
            class="vca-button-small"
            @click="addOwnCrew()"
          >
            {{
              $t("context_button.add", {
                0: $t("events.list.crew.own"),
              })
            }}
          </button>
          <button
            v-if="user.crew.id != ''"
            class="vca-button-small"
            @click="setOwnCrew()"
          >
            {{
              $t("context_button.select", {
                0: $t("events.list.crew.own"),
              })
            }}
          </button>
        </vca-field-row>
      </vca-card>
    </vca-row>
    <vca-column>
      <vca-card>
        <h3>{{ $t("events.participations.status.header") }}</h3>
        <vca-row>
          <vca-checkbox
            v-for="entity in participationsTypes"
            v-model="value.state"
            :key="entity"
            :id="entity"
            >{{ $t("events.participations.status." + entity) }}</vca-checkbox
          >
          <div
            v-if="participationsTypes.length == 0"
            class="vca-center highlight"
          >
            {{ $t("email.event.group.empty") }}
          </div>
        </vca-row>
      </vca-card>
    </vca-column>
  </vca-column>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AccountEventFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
    participations: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    setOwnCrew() {
      this.value.crews = [{ value: this.user.crew.crew_id }];
    },
    addOwnCrew() {
      if (!this.value.crews.find((el) => el.value == this.user.crew.crew_id)) {
        this.value.crews.push({ value: this.user.crew.crew_id });
      }
    },
  },
  computed: {
    eventsList() {
      return this.participations.filter((participation) => {
        if (participation.event.crew_id == "") {
          return false;
        }
        participation.event.crew.title = participation.event.crew.name;
        participation.event.crew.label = participation.event.crew.name;
        participation.event.crew.value = participation.event.crew.id;
        return true;
      });
    },
    crewList() {
      let crew_list = [
        ...new Map(
          this.eventsList.map((item) => [
            item.event.crew["id"],
            item.event.crew,
          ])
        ).values(),
      ];
      // if there's any event without a crew_id, this is an office event - so we add the office to the crew dropdown
      if (
        this.participations.find((item) => {
          return item.event.crew_id === "";
        }) !== undefined
      ) {
        crew_list.push({
          value: "",
          title: this.$t("events.list.crew.office_hh"),
          label: this.$t("events.list.crew.office_hh"),
        });
      }
      return crew_list;
    },
    participationsTypes() {
      return ["confirmed", "requested", "rejected", "withdrawn"];
    },
    eventtypes() {
      return this.event_types.filter((el) => {
        return el.value != "tour";
      });
    },
    ...mapGetters({
      event_types: "events/eventtypes",
      user: "user/current",
    }),
  },
};
</script>
